<template>
    <v-dialog v-model="shown" max-width="800">
        <v-card height="70vh">
            <div class="pa-4" style="height: 100%;">
                <textarea id="textarea" readonly :value="JSON.stringify(value, null, '  ')" style="width: 100%; height: 100%;"></textarea>
            </div>
        </v-card>
    </v-dialog>    
</template>
<script>
export default {
    data: () => ({
        shown: false,
    }),
    props: ['value'],
    methods: {
        show() { this.shown = true; },
        hide() { this.shown = false; },
    }
}
</script>
<style scoped>
#textarea {
    font-family: monospace;
    font-size: 12px;
}
</style>