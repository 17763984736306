var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-combobox", {
    staticClass: "mt-2",
    attrs: { multiple: "", items: _vm.items, value: _vm.value },
    on: {
      input: function ($event) {
        return _vm.$emit("input", $event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function ({ item, index }) {
          return [
            _c(
              "draggable",
              _vm._b(
                {
                  attrs: { id: index, list: _vm.items, move: _vm.move },
                  on: {
                    input: function ($event) {
                      return _vm.$emit("input", $event)
                    },
                    change: _vm.change,
                  },
                },
                "draggable",
                _vm.dragOptionsChips,
                false
              ),
              [
                _c(
                  "v-chip",
                  {
                    staticClass: "ma-1",
                    attrs: { small: "", label: "" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                      mousedown: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _vm._v(" " + _vm._s(item) + " "),
                    _c(
                      "v-icon",
                      {
                        attrs: { small: "", right: "" },
                        on: {
                          click: function ($event) {
                            return _vm.value.splice(index, 1)
                          },
                        },
                      },
                      [_vm._v("mdi-close-circle")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }