var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "800" },
      model: {
        value: _vm.shown,
        callback: function ($$v) {
          _vm.shown = $$v
        },
        expression: "shown",
      },
    },
    [
      _c("v-card", { attrs: { height: "70vh" } }, [
        _c("div", { staticClass: "pa-4", staticStyle: { height: "100%" } }, [
          _c("textarea", {
            staticStyle: { width: "100%", height: "100%" },
            attrs: { id: "textarea", readonly: "" },
            domProps: { value: JSON.stringify(_vm.value, null, "  ") },
          }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }