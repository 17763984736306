<template>
    <v-main>
        <page-toolbar :title="$t('console.responses.toolbar.title')">
            <template #contents>
                <download-menu-button
                    :headers="headers"
                    :headers-with-leaf-answer-keys="headersWithLeafAnswerKeys"
                    :responses="responses"
                />
            </template>
        </page-toolbar>

        <response-table
            :client="client"
            :project-name="projectName"
            :template-names="templateNames"
            @response-update="onResponseUpdate"
            @header-update="onHeaderUpdate"
        />
    </v-main>
</template>

<script>
import PageToolbar from '@/components/ui/PageToolbar'
import ResponseTable from './ResponseTable'
import DownloadMenuButton from './DownloadMenuButton'

export default {
    components: {
        PageToolbar,
        ResponseTable,
        DownloadMenuButton
    },
    data: () => ({
        templateNames: [],
        headers: [],
        headersWithLeafAnswerKeys: [],
        responses: [],
    }),
    props: ['client', 'projectName'],
    methods: {
        async listTemplates() {
            this.templateNames = await this.client.resource.listTemplates({ project_name: this.projectName });
        },
        onHeaderUpdate(headers, headersWithLeafAnswerKeys) { this.headers = headers; this.headersWithLeafAnswerKeys = headersWithLeafAnswerKeys; },
        onResponseUpdate(responses) { this.responses = responses; }
    },
    watch: {
        projectName(name) { if(name) this.listTemplates(); },
    },
    mounted() {
        this.client.invokeOnOpen(() => {
            if(this.projectName) this.listTemplates();
        })
    }
}
</script>
