<template>
    <v-menu offset-y>
        <template #activator="{ attrs, on }">
            <v-btn
                text
                v-bind="attrs"
                v-on="on"
                color="indigo"
                :disabled="responses.length == 0"
            >
                <v-icon left>mdi-download</v-icon>
                {{ $t('console.responses.toolbar.buttons.download.label') }}
            </v-btn>
        </template>
        <v-list dense>
            <v-list-item @click="downloadJSON(false)">
                <v-list-item-title><v-icon small left>mdi-code-json</v-icon>{{ $t('console.responses.toolbar.buttons.download.menu.allJson') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="downloadJSON(true)">
                <v-list-item-title><v-icon small left>mdi-code-json</v-icon>{{ $t('console.responses.toolbar.buttons.download.menu.selectedJson') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="downloadCSV(false)">
                <v-list-item-title><v-icon small left>mdi-file-table-outline</v-icon>{{ $t('console.responses.toolbar.buttons.download.menu.allCsv') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="downloadCSV(true)">
                <v-list-item-title><v-icon small left>mdi-file-table-outline</v-icon>{{ $t('console.responses.toolbar.buttons.download.menu.selectedCsv') }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script>
import { standardDateTimeFormat } from '@/lib/utils';
export default {
    props: {
        headers: { type: Array, default: () => ([]) },
        headersWithLeafAnswerKeys: { type: Array, default: () => ([]) },
        responses: { type: Array, default: () => ([]) },
    },
    computed: {
        onlySelectedColumnsResponsesForCSV() {
            return this.responses.map(r => {
                let data = {};
                this.headers.slice().forEach(h => {
                    data[h.value] = this.accessObjectByDottedKey(r, h.value);
                });
                return data;
            });
        },
        allLeafColumnsResponsesForCSV() {
            return this.responses.map(r => {
                let data = {};
                this.headersWithLeafAnswerKeys.slice().forEach(h => {
                    data[h.value] = this.accessObjectByDottedKey(r, h.value);
                });
                return data;
            });
        },
        onlySelectedColumnsResponsesForJSON() {
            return this.responses.map(r => {
                let data = {};
                this.headers.slice().forEach(h => {
                    data[h.value] = r[h.value];
                });
                return data;
            });
        }
    },
    methods: {
        accessObjectByDottedKey (obj, key) {
            var keys = key.split('.');
            var value = obj;
            for (var layer = 0, recursive = keys.length; layer <= recursive - 1; layer++) {
                let key = keys[layer].match(/^\d+$/) ? parseInt(keys[layer]) : keys[layer];
                value = value[key];
                if(value === undefined) break;
            }
            return value;
        },
        downloadJSON(onlySelected) {
            const responses = onlySelected ? this.onlySelectedColumnsResponsesForJSON : this.responses;
            const blob = new Blob(
                [JSON.stringify(responses, null, '  ')],
                { type: 'application/json' }
            );
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'responses.json';
            link.click();
        },
        downloadCSV(onlySelected) {
            const responses = onlySelected ? this.onlySelectedColumnsResponsesForCSV : this.allLeafColumnsResponsesForCSV;
            let responsesCSV = Object.keys(responses[0]).join(',');
            responsesCSV += '\n'
                + responses.map((r) => (
                    Object.entries(r).map(([k, v]) => {
                        if(k === '_created_at') v = standardDateTimeFormat(v);
                        return `"${v}"`
                    }).join(',')
                )).join('\n');
            const blob = new Blob(
                [responsesCSV],
                { type: 'text/csv' }
            );
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'responses.csv';
            link.click();
        }
    }
}
</script>
