<template>
    <v-combobox
        multiple
        :items="items"
        :value="value"
        class="mt-2"
        @input="$emit('input', $event)"
    >
        <template v-slot:selection="{ item, index }">
            <draggable
                :id="index"
                :list="items"
                @input="$emit('input', $event)"
                v-bind="dragOptionsChips"
                :move="move"
                @change="change"
            >
                <v-chip small class="ma-1" label @click.stop @mousedown.stop>
                    {{  item }}
                    <v-icon small right @click="value.splice(index, 1)">mdi-close-circle</v-icon>
                </v-chip>
            </draggable>
        </template>
    </v-combobox>
</template>
<script>
import Draggable from 'vuedraggable'

export default {
    components: { Draggable },
    data: () => ({
        dragged: {
            from: -1,
            to: -1,
            newIndex: -1
        },
    }),
    props: ['value', 'label', 'items'],
    methods: {
        move: function(value) {
            this.dragged = {
                from: parseInt(value.from.id),
                to: parseInt(value.to.id),
                newIndex: value.draggedContext.futureIndex,
            }
        },
        change: function(value) {
            if (value.removed) {
                // insert
                this.value.splice(this.dragged.to+this.dragged.newIndex, 0, this.value[this.dragged.from])
                // delete
                if (this.dragged.from < this.dragged.to) {// LTR
                    this.value.splice(this.dragged.from, 1)
                } else {// RTL
                    this.value.splice(this.dragged.from + 1, 1)
                }
            }
        }
    },
    computed: {
        dragOptionsChips() {
            return {
                animation: 200,
                group: "group",
                disabled: false,
                ghostClass: "ghost",
                sort: true,
            };
        }
    },
}
</script>